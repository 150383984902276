<template>
  <div class="resortSelection">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="resorts">
        <div
          class=""
        >
          <Multiselect
            id="resorts"
            v-model="resort"            
            :options="resorts"
            placeholder="All Resorts"
            :custom-label="customLabel"
            label="name"
            :close-on-select="true"
            class="form-control col-4"
          />
        </div>
      </template>
      <p v-else>
        {{ $t('noDataAvailable') }}
      </p>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ResortSelection',
  components: {
    Multiselect: () => import('vue-multiselect'),
  },
  props: {
    name: {
      type: String,
      required: false,
      default: null
    },
    id: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      resorts: null,
      resort: null,
    }
  },
  computed: {
    selectedResort () {
      return this.resort ? this.resort.id : -1;
    }
   },
  watch: {
    selectedResort (val) {
      this.$emit('resortSelected', val);
    }
  },
  created () {
    this.getResorts();
  },
  metaInfo () {
    return {
        title: this.$t('menu.resorts')
    }
  },
  methods: {
     customLabel ({ id, name }) {
      return `${id} — ${name}`
    },
    getResorts () {
      this.loading = true;
      this.axios.get(`/Resort/GetAllResorts`)
        .then((response) => {
          if (response) {
            this.resorts = response.data;
            if(this.id) {
              this.resort = this.resorts.find(x => x.id == parseInt(this.id));
            }
            else if(this.name) {
              this.resort = this.resorts.find(x => x.name == this.name);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>